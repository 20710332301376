import React from "react"

const Imparables = (props) => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 2834.6 1642.4" {...props}>
    <g>
      <path fill="currentColor" d="M916.8,1620.1L972.1,17c1.5-5.9,5.2-8.9,11.1-8.9h159.3c7.4,0,11.1,3,11.1,8.9l55.3,1603.1
        c0,8.9-3.7,13.3-11.1,13.3H1103c-7.4,0-11.1-2.9-11.1-8.9l-4.4-322.3h-49.4l-4.4,322.3c0,5.9-3.7,8.9-11.1,8.9h-94.8
        C921.9,1633.4,916.7,1629.4,916.8,1620.1z M1072.7,177.3H1053l-17.3,1013.3h54.4L1072.7,177.3z"/>
      <path fill="currentColor" d="M9,1622.3V19.2c0-7.4,3.7-11.1,11.1-11.1H113c7.4,0,11.1,3.7,11.1,11.1v1603.1c0,7.4-3.7,11.1-11.1,11.1H20.1
        C12.7,1633.4,9,1629.7,9,1622.3z"/>
      <path fill="currentColor" d="M874.9,48c22.1,26.6,33.2,68.6,33.2,126.1v682.1c0,57.5-11.4,99.6-34.3,126.1c-22.9,26.6-59.4,39.8-109.5,39.8
        h-4.4v600.2c0,7.4-3.7,11.1-11.1,11.1h-92.9c-7.4,0-11.1-3.7-11.1-11.1V19.2c0-7.4,3.7-11.1,11.1-11.1h108.4
        C815.9,8.1,852.8,21.4,874.9,48z M788.6,917c2.9-5.2,4.4-12.2,4.4-21c0-8.9,0-15.5,0-19.9V152.3V139c0-8.9-1.9-15.8-5.5-21
        c-3.7-5.2-11.4-7.7-23.2-7.7h-4.4v814.5h4.4C777.5,924.7,785.6,922.2,788.6,917z"/>
      <path fill="currentColor" d="M1534.8,1620.1L1590.1,17c1.5-5.9,5.2-8.9,11.1-8.9h159.3c7.4,0,11.1,3,11.1,8.9l55.3,1603.1
        c0,8.9-3.7,13.3-11.1,13.3h-99.6c-7.4,0-11.1-2.9-11.1-8.9l-4.4-322.3H1661l-4.4,322.3c0,5.9-3.7,8.9-11.1,8.9h-99.6
        C1539.9,1633.4,1534.7,1629.4,1534.8,1620.1z M1690.8,177.3H1671l-12.6,1013.3h44.9L1690.8,177.3z"/>
      <path fill="currentColor" d="M1516.3,1617.9c0,10.3-4.4,15.5-13.3,15.5h-92.9c-5.9,0-9.6-2.9-11.1-8.9c-3-14.7-5.5-38.7-7.7-71.9
        c-2.2-33.2-3.3-60.9-3.3-83V900.4v-15.5c0-13.3-1.5-22.5-4.4-27.7c-3-5.2-8.9-7.7-17.7-7.7h-11.1v772.8c0,7.4-3.7,11.1-11.1,11.1
        h-92.9c-7.4,0-11.1-3.7-11.1-11.1V19.2c0-7.4,3.7-11.1,11.1-11.1h115.1c48.7,0,83.7,13.3,105.1,39.8
        c21.4,26.6,32.1,68.6,32.1,126.1v500.6c0,69.4-22.1,109.2-66.4,119.5v8.9c23.6,3,40.6,11.8,50.9,26.6
        c10.3,14.8,15.5,39.8,15.5,75.2v513.9C1503.1,1510.2,1507.5,1576.6,1516.3,1617.9z M1384.7,744.4c2.2-6.6,3.3-18.1,3.3-34.3V147.9
        V139c0-19.2-7.4-28.8-22.1-28.8h-11.1v644.1h11.1C1376.2,754.4,1382.5,751,1384.7,744.4z"/>
      <path fill="currentColor" d="M2100.4,758.8c-14,20.7-32.1,32.5-54.2,35.4v8.9c50.1,3,75.2,35.4,75.2,97.4v575.8
        c0,57.5-10.7,98.1-32.1,121.7c-21.4,23.6-59.4,35.4-114,35.4h-106.2c-7.4,0-11.1-3.7-11.1-11.1V19.2c0-7.4,3.7-11.1,11.1-11.1
        h106.2c50.1,0,87,12.2,110.6,36.5c23.6,24.3,35.4,64.6,35.4,120.6v513.9C2121.5,711.6,2114.5,738.1,2100.4,758.8z M2006.4,147.9
        c0-14.7-1.9-24.7-5.5-29.9c-3.7-5.2-12.2-7.7-25.5-7.7h-2.2v644.1c16.2-1.5,25.8-5.9,28.8-13.3c2.9-7.4,4.4-20.6,4.4-39.8V158.9
        V147.9z M2002,1527.1c2.9-5.9,4.4-19.2,4.4-39.8V904.8v-11.1c0-14.7-1.9-25.5-5.5-32.1c-3.7-6.6-12.9-10.7-27.7-12.2V1536h2.2
        C1990.1,1536,1999,1533.1,2002,1527.1z"/>
      <path fill="currentColor" d="M2331.6,1527.1v95.2c0,7.4-3.7,11.1-11.1,11.1h-148.3c-7.4,0-11.1-3.7-11.1-11.1V19.2
        c0-7.4,3.7-11.1,11.1-11.1h92.9c7.4,0,11.1,3.7,11.1,11.1v1496.9h44.3C2327.9,1516.1,2331.6,1519.8,2331.6,1527.1z"/>
      <path fill="currentColor" d="M2470.4,125.4v628.9h53.4c7.4,0,11.1,3,11.1,8.9v77.5c0,5.9-3.7,8.9-11.1,8.9h-53.4v666.6h53.4
        c7.4,0,11.1,3.7,11.1,11.1v95.2c0,7.4-3.7,11.1-15.8,11.1h-152.7c-2.6,0-6.3-3.7-6.3-11.1V19.2c0-7.4,3.7-11.1,6.3-11.1H2519
        c12.1,0,15.8,3.7,15.8,11.1v95.2c0,7.4-3.7,11.1-11.1,11.1H2470.4z"/>
      <path fill="currentColor" d="M2598.9,1604.6c-22.1-22.1-33.2-60.5-33.2-115.1V909.9c0-7.4,3.7-11.1,11.1-11.1h90.7
        c7.4,0,11.1,3.7,11.1,11.1v597c0,8.9,1.1,16.3,3.3,22.1c2.2,5.9,7,8.9,14.4,8.9c7.4,0,12.2-2.9,14.4-8.9c2.2-5.9,3.3-13.3,3.3-22.1
        V986.7c0-44.3-5.5-76.7-16.6-97.4c-11.1-20.6-27.7-39.1-49.8-55.3c-32.5-23.6-54.2-46.8-65.3-69.7c-11.1-22.9-16.6-60.1-16.6-111.8
        V152c0-54.6,11.1-92.9,33.2-115.1c22.1-22.1,54.6-33.2,97.4-33.2c42.8,0,75.2,11.1,97.4,33.2c22.1,22.1,33.2,60.5,33.2,115.1v537.5
        c0,7.4-3.7,11.1-11.1,11.1H2725c-7.4,0-11.1-3.7-11.1-11.1V134.6c0-10.3-1.1-18.1-3.3-23.2c-2.2-5.2-7-7.7-14.4-7.7
        c-7.4,0-12.2,2.6-14.4,7.7c-2.2,5.2-3.3,12.9-3.3,23.2v489.2c0,36.9,5.2,63.5,15.5,79.7c10.3,16.3,27.3,34.7,50.9,55.3
        c32.4,25.1,54.2,50.6,65.3,76.3c11.1,25.8,16.6,66.8,16.6,122.8v531.6c0,54.6-11.1,92.9-33.2,115.1c-22.1,22.1-54.6,33.2-97.4,33.2
        C2653.5,1637.8,2621,1626.7,2598.9,1604.6z"/>
      <path fill="currentColor" d="M429.8,1633.4c7.4,0,11.1-2.9,11.1-8.9l47.7-1446.2h26.5L476,1624.5c0,5.9,3.7,8.9,11.1,8.9h95.8
        c7.4,0,11.1-4.4,11.1-13.3V17c0-5.9-3.7-8.9-11.1-8.9H433.9c-7.4,0-11.1,3-11.1,8.9l-34,1332.3h-11.2L343.8,17
        c0-5.9-3.7-8.9-11.1-8.9H183.8c-7.4,0-11.1,3-11.1,8.9v1603.1c0,8.9,3.7,13.3,11.1,13.3h95.8c7.4,0,11.1-2.9,11.1-8.9L251.5,178.3
        h26.4l47.9,1446.2c0,5.9,3.7,8.9,11.1,8.9H429.8z"/>
    </g>
  </svg>
)

export default Imparables
